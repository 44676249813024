.ContactSection {
  .address {
    img{
      width: 30px;
    }
  }
  .form {
    form {
      input {
        width: 100%;
        padding: 3%;
        margin-bottom: 2%;
        outline: none;
        background-color: #efefef;
        border-radius: 5px;
      }
      textarea {
        width: 100%;
        padding: 3%;
        margin-bottom: 2%;
        outline: none;
        background-color: #efefef;
        border-radius: 5px;
      }
    }
  }
}