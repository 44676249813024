.HomeHeader {
  background-image: linear-gradient(rgb(0, 0, 0, 0.2), rgb(0, 0, 0, 0.3)), url(../../Img/bg-1.jpg);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

@media (max-width: 600px) {
  .HomeHeader {
    .pl-20 {
      padding-left: 3%;
    }
  }
}